// Find the code here
// https://codepen.io/alexdevp/pen/RNELPV

window.onload = () => {
    AwesomeAudioPlayer.init();
    ScreenRecorder.init();
    SocialButtons.init();

    const submitButton = document.getElementById("submitButton");
    const errorsDiv = document.getElementById("errors");
    const formDiv = document.getElementById("form");
    const songDiv = document.getElementById("song");
    const backgroundImage = document.getElementById("backgroundImage");
    const backgroundImageLabel = document.getElementById("backgroundImageLabel");
    const backgroundVideo = document.getElementById("backgroundVideo");
    const backgroundVideoLabel = document.getElementById("backgroundVideoLabel");
    const audio = document.getElementById("audioFile");
    const audioLabel = document.getElementById("songLabel");

    submitButton.addEventListener("click", () => {
        const errors = getErrors();

        if (errors.length > 0) {
            errorsDiv.innerHTML = errors.join('<br/>');
            return;
        }

        PlayFile();
    });

    backgroundImage.onchange = function (event) {
        const files = event.target.files;
        showChoseFileName(backgroundImageLabel, fileName(files));
        changeBackground(files);
        backgroundVideoLabel.style.display = 'none';
    }

    backgroundVideo.onchange = function (event) {
        const files = event.target.files;
        showChoseFileName(backgroundVideoLabel, fileName(files));
        changeBackground(null);
        backgroundImageLabel.style.display = 'none';
        playBackground(files);
    }

    audio.onchange = function (event) {
        showChoseFileName(audioLabel, fileName(event.target.files[0]));
    }

    function getErrors() {
        const songName = document.getElementById("songName");
        const artistName = document.getElementById("artistName");
        const audioFile = document.getElementById("audioFile");

        let errors = [];

        if (!songName.value) {
            errors.push("Please add a song");
        }

        if (!artistName.value) {
            errors.push("Please add a artist");
        }

        if (!audioFile.value) {
            errors.push("Please add an audio");
        }

        return errors;
    }

    function toggleView() {
        formDiv.classList.add('hidden');
        songDiv.classList.remove('hidden');
    }

    function updateTrack() {
        const songName = document.getElementById("songName").value;
        const artistName = document.getElementById("artistName").value;
        document.querySelector('#song .artistlabel').setAttribute("name", artistName);
        document.querySelector('#song .songlabel').setAttribute("name", songName);
    }

    function PlayFile() {
        toggleView();
        updateTrack();

        const fileNode = document.getElementById("audioFile");
        const file = fileNode.files[0];
        const reader = new FileReader();

        reader.onload = () => {
            AwesomeAudioPlayer.context.decodeAudioData(reader.result, (buffer) => {
                AwesomeAudioPlayer.source.buffer = buffer;
                ScreenRecorder.source.buffer = buffer;
            });

            ScreenRecorder.start();
        }

        reader.readAsArrayBuffer(file);
    }

    function changeBackground(files) {
        if (files == null) {
            document.body.style.backgroundImage = 'none';
            return;
        }

        let url = URL.createObjectURL(files[0]);
        document.body.style.backgroundImage = `url(${url}`;

        if (files.length > 1) {
            let imageNum = 0;

            setInterval(() => {
                imageNum = imageNum < files.length - 1 ? imageNum + 1 : 0;
                url = URL.createObjectURL(files[imageNum]);
                document.body.style.backgroundImage = `url(${url}`;
            }, 20000);
        }
    }

    function playBackground(files) {
        const video = document.getElementById('backgroundVideoPlayArea');

        let url = URL.createObjectURL(files[0]);

        if (files.length > 1) {
            let videoNum = 0;
            video.addEventListener('ended', () => {
                videoNum = videoNum < files.length - 1 ? videoNum + 1 : 0;
                url = URL.createObjectURL(files[videoNum]);
                video.setAttribute('src', url);
                video.play();
            });
        } else {
            video.setAttribute('loop', true);
        }

        video.setAttribute('src', url);
        video.play();
    }

    function showChoseFileName(element, text) {
        element.innerText = text;
    }

    function fileName(files) {
        if (files.length > 1) {
            return 'Multiple Files';
        }

        const chunks = files[0].name.split('.');
        chunks.pop();
        const name = chunks.join(' ');

        return titleCase(name);
    }

    function titleCase(str) {
        return str.toLowerCase().split(' ').map(function (word) {
            return (word.charAt(0).toUpperCase() + word.slice(1));
        }).join(' ');
    }
}